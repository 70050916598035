/**
 * This is a utility to make axios calls. The following methods can be imported in the respective classes and used to make the calls.
 */
import axios from "axios";
import { BASE_URL } from "../constants";
import {
  getOrgNr,
  saveToken,
  getTokenAccess,
  getTokenRefresh,
} from "../localstorage";
import { logout } from "../index";
import * as Sentry from "@sentry/react";
import { clearAll } from "../localstorage";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  xsrfHeaderName: "X-CSRF-Token",
  xsrfCookieName: "csrftoken",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getTokenAccess();
    const org = getOrgNr();
    let headers = {
      Authorization: token ? `Bearer ${token}` : "",
    };

    if (org) {
      headers["X-Organisation-Number"] = org;
    }
    config.headers = { ...config.headers, ...headers };
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // Errors in refresh, just reset state
    if (
      originalRequest.url ===
      originalRequest.baseURL + "/api/token/refresh/"
    ) {
      // Since we do parallel requsts we only need to trigger this once
      // This is quite uqly and should be rewritten at some point
      if (getTokenRefresh()) {
        clearAll();
        // logout(
        //   "/login",
        //   "For your security you have been logged out due to inactivity."
        // );
      }
      return Promise.reject(error);
    }

    if (error?.response?.status === 409) {
      // Duplicate entry, just reject early
      return Promise.reject(error);
    }
    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== originalRequest.baseURL + "/auth/login/"
    ) {
      originalRequest._retry = true;
      const token = await refreshAccessToken();
      saveToken(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token.access;
      return axiosInstance(originalRequest);
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refresh = getTokenRefresh();
  if (!refresh) {
    logout("/login");
    return Promise.reject();
  }
  return doPost("api/token/refresh/", {
    refresh,
  });
};

export const doGet = async (url, params) => {
  const apiURL = `${BASE_URL}/${url}`;

  const result = params
    ? await axiosInstance.get(apiURL, { params })
    : await axiosInstance.get(apiURL);

  return result.data;
};

export const doDownload = async (url) => {
  const apiURL = `${BASE_URL}/${url}`;
  const result = await axiosInstance.get(apiURL, { responseType: "blob" });
  return result.data;
};

export const doPost = async (url, data) => {
  const apiURL = `${BASE_URL}/${url}`;

  const result = await axiosInstance.post(apiURL, data);

  return result.data;
};

export const doPut = async (url, data) => {
  const apiURL = `${BASE_URL}/${url}`;

  const result = await axiosInstance.put(apiURL, data);

  return result.data;
};

export const doPatch = async (url, data) => {
  const apiURL = `${BASE_URL}/${url}`;

  const result = await axiosInstance.patch(apiURL, data);

  return result.data;
};

export const doDelete = async (url, id) => {
  const apiURL = `${BASE_URL}/${url}/${id}/`;

  const result = await axiosInstance.delete(apiURL);

  return result.data;
};
