import { Route, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { CountryContext } from "../hooks/useCountry";
import { UserContext } from "../hooks/useWhoAmI";
import { logout } from "../common";
import { getLocalStorageItem, StorageKeys } from "../common/localstorage";
import SideBar from "../components/sideBar";

const PrivateRoute = ({ children, ...rest }) => {
  const { loading: loadingCountry } = useContext(CountryContext);
  const { loading, me, updateWhoAmI } = useContext(UserContext);
  let isAuthenticated =
    getLocalStorageItem(StorageKeys.REFRESH) !== null ? true : false;

  const location = useLocation();
  const redirect = () => {
    logout(`/login?redirectTo=${location.pathname}${location.search}`);
  };

  useEffect(() => {
    if (!me && isAuthenticated && !loading) {
      updateWhoAmI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, isAuthenticated, loadingCountry, loading]);

  if (!isAuthenticated) {
    redirect();
    return null;
  }

  if (loadingCountry || loading || !me) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={() => {
        return <SideBar>{children}</SideBar>;
      }}
    />
  );
};

export default PrivateRoute;
