import { Icon } from "@iconify/react";
import { capitalize, daysUntil } from "../../common/utils";

export const BankConnectionList = ({
  bankConnections,
  setPreSelectedBank,
  setStep,
}) => {
  const icons = {
    ACTIVE: "eva:checkmark-circle-outline",
    EXPIRING: "mingcute:alert-line",
    EXPIRED: "eva:alert-circle-outline",
  };

  const iconColors = {
    ACTIVE: "#450B70",
    EXPIRING: "#fd884d",
    EXPIRED: "#dc2626",
  };

  if (bankConnections.length === 0) return null;

  const shouldDisplayValidUntil = (valid_until) => {
    if (!valid_until) return false;
    const amountDays = daysUntil(valid_until);
    if (amountDays >= 0 && amountDays <= 8) {
      return true;
    }
    return false;
  };

  return (
    <ul className="border-t">
      {bankConnections?.map((account, index) => (
        <li
          key={`${account.name}+${index}`}
          className="grid grid-cols-[40px_minmax(160px,_1fr)_40px] gap-3 border-b border-float-grey-10 last:border-none px-8 py-3"
        >
          {account.logo ? (
            <img
              src={account.logo}
              alt="bank_logo"
              className="rounded-md"
              title={account?.bank_name}
            />
          ) : (
            <div className="bg-float-purple-6 px-2 rounded-md h-10 flex flex-wrap content-center">
              <Icon icon="circum:bank" width={24} color="#450B70" />
            </div>
          )}

          <div className="grow my-auto">
            <p className="text-float-purple-2 font-medium text-sm leading-6 truncate">
              {account.alias ? <span>{account.alias}</span> : "Account"}
              {account.account_number && <span> {account.account_number}</span>}
              {account.iban && <span> / {account.iban}</span>}
              {account.currency && <span> ({account.currency})</span>}
            </p>
            <div className="flex">
              {shouldDisplayValidUntil(account.valid_until) && (
                <div className="flex my-auto">
                  <Icon icon="formkit:date" width={12} className="my-auto" />
                  <p className="text-xs font-normal float-black-90 leading-5 flex whitespace-nowrap ml-1">
                    Connection expires in {daysUntil(account.valid_until)} days
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="mt-2 flex flex-row">
              {account?.status !== "ACTIVE" && (
                <button
                  onClick={() => {
                    setPreSelectedBank(account.institution_id);
                    setStep(13);
                  }}
                  className="text-center p-2 mr-4 text-xs justify-center flex gap-2"
                >
                  <Icon icon="el:refresh" width={14} />
                  Renew
                </button>
              )}

              {account.status && (
                <div className="tooltip flex">
                  <span className="text-xsmall mt-4">
                    {capitalize(account.status)}
                  </span>
                  <Icon
                    icon={icons[account.status]}
                    width={24}
                    color={iconColors[account.status]}
                  />
                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
