export const capitalize = (s) =>
  s && s[0].toUpperCase() + s.slice(1).toLowerCase();

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const daysSince = (date) => {
  const today = new Date();
  const targetDate = new Date(date);

  // Calculate the difference in time
  const timeDifference = today - targetDate;

  // Convert time difference from milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const daysUntil = (date) => {
  const today = new Date();
  const targetDate = new Date(date);

  // Calculate the difference in time
  const timeDifference = targetDate - today;

  // Convert time difference from milliseconds to days
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};
