import { useEffect, useState } from "react";
import {
  getExpandedAccordion,
  removeExpandedAccordion,
  StorageKeys,
} from "../../../../../common/localstorage";
import useQuery from "../../../../../hooks/useQuery";

export const useExpandedAccordion = () => {
  const search = useQuery();
  const [accountingExpanded, setAccountingExpanded] = useState(false);
  const [subscriptionExpanded, setSubscriptionExpanded] = useState(false);
  const [transactionExpanded, setTransactionExpanded] = useState(false);

  useEffect(() => {
    setAccountingExpanded(!!getExpandedAccordion(StorageKeys.ACCOUNTING));
    setSubscriptionExpanded(!!getExpandedAccordion(StorageKeys.SUBSCRIPTION));
    setTransactionExpanded(!!getExpandedAccordion(StorageKeys.TRANSACTION));

    if (search.get("transactionExpanded") === "true") {
      setTransactionExpanded(true);
    }
    removeExpandedAccordion(StorageKeys.ACCOUNTING);
    removeExpandedAccordion(StorageKeys.SUBSCRIPTION);
    removeExpandedAccordion(StorageKeys.TRANSACTION);
  }, [
    setAccountingExpanded,
    setSubscriptionExpanded,
    setTransactionExpanded,
    search,
  ]);

  return {
    accountingExpanded,
    subscriptionExpanded,
    transactionExpanded,
  };
};
