import useQuery from "./useQuery";

export const useSuccessLogin = () => {
  const search = useQuery();

  const setCookiesAndLogin = ({ setShowCompanyModal, me, url, history }) => {
    const redirectTo = search.get("redirectTo");

    if (me?.company?.length > 1 && setShowCompanyModal) {
      setShowCompanyModal(true);
    }

    if (url) {
      window.location.href = url;
    } else {
      history.push(redirectTo === null ? "/dashboard" : redirectTo);
    }
  };

  return {
    setCookiesAndLogin,
  };
};
