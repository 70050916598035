import { doGet, doPost, doPut } from "../common/axiosUtil";

export const initiateLogin = async (
  bank,
  redirect = "/dashboard",
  invite_token = null,
  redirect_to = null
) => {
  const post = await doPost(`v1/initiate_login/?redirect=${redirect}`, {
    bank,
    invite_token,
    redirect_to,
  });
  return post;
};

export const exchangeCode = async (code, invite_token = null) => {
  if (invite_token !== null) {
    const post = await doPost("v1/exchange_code/", {
      code: encodeURIComponent(code),
      invite_token: invite_token,
    });

    return post;
  } else {
    const post = await doPost("v1/exchange_code/", {
      code: encodeURIComponent(code),
    });

    return post;
  }
};

export const getWhoAmI = async () => {
  return doGet("v1/user/");
};

export const verifyEmail = async (email) => {
  const post = await doPost("auth/verify-email/", email);

  return post;
};

export const verifyEmailPut = async (code) => {
  const put = await doPut("auth/verify-email/", code);

  return put;
};

export * from "./ais";
export * from "./company";
export * from "./loan";
export * from "./user";
export * from "./auth";
export * from "./subscription";
export * from "./invoice";
export * from "./config";
export * from "./application";
