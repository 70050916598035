import { useFormik } from "formik";
import BankIDButton from "../../components/bankIDButton";
import Loading from "../../components/loading";
import useQuery from "../../hooks/useQuery";
import { useLoginEid } from "../../hooks/useLoginEid";

const Automated = ({ country }) => {
  const { login } = useLoginEid();
  const search = useQuery();
  let invite_token = search.get("invite_token");
  let redirect_to = search.get("redirectTo");

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      country,
    },
    onSubmit: async (values) => {
      const params = {
        country: country,
      };
      await login({ values: params, formik, invite_token, redirect_to });
    },
  });
  const doAction = (e) => formik.handleSubmit(e);

  return (
    <>
      {formik.isSubmitting && <Loading />}
      <form className="w-full text-left mt-4">
        <BankIDButton
          className={`mt-8 w-full`}
          country={country}
          disabled={formik.isSubmitting}
          onClick={doAction}
        />
      </form>
    </>
  );
};

export default Automated;
