import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { postLoan, getActivationDateConfiguration } from "../../api";
import { addWorkingDays } from "../../utils";
import { AccountSelector } from "../accountSelector";
import { AccountVerification } from "../accountVerification";
import { ConfirmLoan } from "../confirmLoan";
import Info from "../info";
import { InitiateVerified } from "../initiateVerified";
import { ModalContext } from "../modal";
import useGetValidationSchema from "./validation/useGetValidationSchema";
import useLoanChart from "../loanChart/hooks/useLoanChart";
import { CountryContext } from "../../hooks/useCountry";
import { ToastType } from "../../types";
import { Toast } from "../toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Selector = (props) => {
  const history = useHistory();
  const { country } = useContext(CountryContext);
  const { validationSchema } = useGetValidationSchema();
  const [termsOfferArray, setTermsOfferArray] = useState([[]]);
  const [payOutConfig, setPayOutConfig] = useState({});
  //eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [payOutDate, setPayOutDate] = useState(null);
  const [activationDate, setActvationDate] = useState();

  const inputRef = useRef(null);
  const onTextClick = () => inputRef.current.focus();

  let formatter = new Intl.NumberFormat(country?.code);
  const { setContent, setVisible, setTitle, setWrapperCSS } =
    useContext(ModalContext);
  const {
    used_credit,
    total_credit,
    interest,
    id,
    currency,
    loanChart,
    validMaxDate,
    accounts,
    refreshAccounts,
    hasActiveConnections,
  } = props;

  let shortFormFormatter = Intl.NumberFormat(country?.code, {
    notation: "compact",
  });

  const maxLoanAmount = total_credit - used_credit;
  const minLoanAmount =
    total_credit * 0.1 < maxLoanAmount
      ? Math.floor(total_credit * 0.1)
      : Math.floor(maxLoanAmount * 0.1);

  const getTermDefaultValue = () => {
    const entries = Object.entries(interest);
    const termsInterestOffer = entries.filter(
      (entry) => !Object.values(entry[1]).every((x) => x === null)
    );
    const defaultTerm = termsInterestOffer.find((entry) => entry[0] === "12");
    if (defaultTerm) {
      return defaultTerm[0];
    }
    return termsInterestOffer[termsInterestOffer.length - 1][0];
  };

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: {
      amount: Math.floor((total_credit - used_credit) / 1000) * 1000,
      grace_period: "0",
      term: getTermDefaultValue(),
      activation_date: null, //  payOutDate?.toISOString().split("T")[0]
    },
    validationSchema: validationSchema(
      formatter,
      minLoanAmount,
      currency,
      total_credit,
      used_credit
    ),
  });

  const handleTerm = (e) => {
    if (interest[e.target.value][formik.values.grace_period] === null) {
      formik.setFieldValue("grace_period", "0");
    }
    formik.handleChange(e);
  };

  const handleDate = (date, e) => {
    setActvationDate(date);
    formik.handleChange(e);
  };

  const formatInput = (value) => {
    return `${formatter
      .formatToParts(value)
      .map((p) => {
        switch (p.type) {
          case "currency":
          case "literal":
          case "integer":
            return p.value;
          case "group":
            return " ";

          default:
            return null;
        }
      })
      .join("")}`;
  };

  const dateFormatter = new Intl.DateTimeFormat("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const { setLoanChartValues } = useLoanChart(formatInput, formik);

  useEffect(() => {
    if (activationDate) {
      setLoanChartValues(
        loanChart,
        setTermsOfferArray,
        interest,
        activationDate
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.errors.amount, activationDate]);

  useEffect(() => {
    const fetchActivationDateConfiguration = async () => {
      try {
        setLoading(true);
        const response = await getActivationDateConfiguration(
          Math.round(formik.values.amount),
          currency,
          id
        );
        setPayOutConfig(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchActivationDateConfiguration();
  }, [formik.values.amount, currency, id]);

  useEffect(() => {
    const calculatePayOutDate = () => {
      let leadTime = payOutConfig?.lead_time;
      let calculatedDate = addWorkingDays(leadTime);

      const adjustToBusinessDay = (date) => {
        // Get day of week (0 = Sunday, 6 = Saturday)
        const day = date.getDay();
        if (day === 0) {
          // Sunday
          date.setDate(date.getDate() + 1);
        } else if (day === 6) {
          // Saturday
          date.setDate(date.getDate() + 2);
        }
        return date;
      };

      let needsAdjustment = true;
      while (needsAdjustment) {
        needsAdjustment = false;
        const dayOfMonth = calculatedDate.getDate();
        // Check if date falls in excluded period first
        if (
          payOutConfig?.exclude_start_day &&
          payOutConfig?.exclude_end_day &&
          dayOfMonth >= payOutConfig.exclude_start_day &&
          dayOfMonth <= payOutConfig.exclude_end_day
        ) {
          // Move to first day after exclude_end_day
          const currentMonth = calculatedDate.getMonth();
          const currentYear = calculatedDate.getFullYear();
          calculatedDate = new Date(
            currentYear,
            currentMonth,
            payOutConfig.exclude_end_day + 1
          );
          calculatedDate = adjustToBusinessDay(calculatedDate);
          needsAdjustment = true;
          continue;
        }

        // If we're before start_day, move directly to start_day in current month
        if (payOutConfig?.start_day && dayOfMonth < payOutConfig.start_day) {
          const currentMonth = calculatedDate.getMonth();
          const currentYear = calculatedDate.getFullYear();
          calculatedDate = new Date(
            currentYear,
            currentMonth,
            payOutConfig.start_day
          );
          calculatedDate = adjustToBusinessDay(calculatedDate);
          needsAdjustment = true;
          continue;
        }

        // If we're after end_day, move to start_day of next month
        if (payOutConfig?.end_day && dayOfMonth > payOutConfig.end_day) {
          const nextMonth = calculatedDate.getMonth() + 1;
          const currentYear = calculatedDate.getFullYear();
          calculatedDate = new Date(
            currentYear,
            nextMonth,
            payOutConfig.start_day
          );

          calculatedDate = adjustToBusinessDay(calculatedDate);
          needsAdjustment = true;
        }
      }

      return calculatedDate;
    };

    if (payOutConfig?.lead_time) {
      setPayOutDate(calculatePayOutDate());
    }
  }, [payOutConfig]);

  useEffect(() => {
    if (payOutDate) {
      setActvationDate(payOutDate);
    }
  }, [payOutDate]);

  const submit = async (account_id) => {
    try {
      const loan = await postLoan(
        Math.round(formik.values.amount),
        Math.round(
          formik.values.amount /
            (formik.values.term - formik.values.grace_period)
        ),
        account_id,
        id,
        parseInt(formik.values.grace_period),
        parseInt(formik.values.term),
        dateFormatter.format(activationDate),
        interest[formik.values.term][formik.values.grace_period] || 0
      );
      if (country.contract_verified) {
        showVerifiedModal(loan);
      } else {
        history.push("/loan?state=signed");
      }
    } catch (ex) {
      let errorMessage = "An unknown error occurred";
      if (ex.response && ex.response.data) {
        const errorData = ex.response.data;
        const firstKey = Object.keys(errorData)[0];
        if (
          firstKey &&
          Array.isArray(errorData[firstKey]) &&
          errorData[firstKey].length > 0
        ) {
          errorMessage = errorData[firstKey][0];
        }
      }
      // TODO: Parse error better.
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: errorMessage,
      });
    }
  };

  if (total_credit === used_credit) {
    return null;
  }

  const isWeekday = (date) => {
    const dayOfMonth = date.getDate();

    if (dayOfMonth < payOutConfig.start_day) {
      return false;
    }

    if (dayOfMonth > payOutConfig.end_day) {
      return false;
    }

    if (payOutConfig?.exclude_start_day && payOutConfig?.exclude_end_day) {
      if (
        payOutConfig?.exclude_start_day <= date.getDate(date) &&
        payOutConfig?.exclude_end_day >= date.getDate(date)
      ) {
        return false;
      }
    }
    const day = date.getDay(date);
    return day !== 0 && day !== 6;
  };

  const showAccountModal = () => {
    setWrapperCSS("w-2/6");
    setContent(<AccountSelector onComplete={showLoanConfirmationModal} />);
    setTitle("Choose account and payment");
    setVisible(true);
  };

  const showLoanConfirmationModal = (account) => {
    setWrapperCSS("w-2/6");
    setContent(
      <ConfirmLoan
        onComplete={submit}
        account={account}
        amount={formatInput(formik.values.amount)}
        gracePeriod={formik.values.grace_period}
        term={formik.values.term}
        activationDate={activationDate}
        interest={interest[formik.values.term][formik.values.grace_period]}
        currency={currency}
        setVisible={setVisible}
      />
    );
    setTitle("Confirm Your Loan");
    setVisible(true);
  };
  const showVerifiedModal = (loan) => {
    setWrapperCSS("w-2/6");
    setContent(
      <InitiateVerified
        loan={loan}
        currency={currency}
        interest={interest}
        country={country.code}
      />
    );
    setTitle("Choose account and payment");
    setVisible(true);
  };

  const showAccountVerificationModal = () => {
    setWrapperCSS(null);
    setContent(
      <AccountVerification
        accounts={accounts}
        currency={currency}
        onSuccess={() => {
          refreshAccounts();
          setVisible(false);
        }}
        onCancel={() => {
          setVisible(false);
        }}
      />
    );
    setTitle("Verify bank account");
    setVisible(true);
  };

  const disabledGrace = (term, grace) => {
    return !interest[term][grace];
  };

  const payoutAccountStatus = (accounts) => {
    if (accounts.length === 0) {
      return "Unverified";
    }
    if (accounts.some((account) => account.verified_status === "verified")) {
      return "Verified";
    }
    if (accounts.some((account) => account.verified_status === "in_progress")) {
      return "Pending verification";
    }
    return "Unverified";
  };

  const getAccountStatusCSS = (status) => {
    if (status === "Pending verification") {
      return "text-float-orange-1";
    } else if (status === "Unverified") {
      return "text-float-red-1";
    }
  };

  const accountVerifiedStatus = payoutAccountStatus(accounts);
  const getContinueHelpText = () => {
    if (props.expiredOffer) {
      return "Your credit offer has expired. Contact float to get it renewed.";
    }
    if (!hasActiveConnections) {
      return "You need to have an active bank connections to continue";
    }
    if (accountVerifiedStatus !== "Verified") {
      return "You will not be able to continue until your payout account has been verified.";
    }
    return "This will generate the contract for you to first review and then sign";
  };

  return (
    <>
      <div className="flex flex-row lg:flex-col flex-1 h-full justify-around lg:space-y-6">
        <div>
          <div className="relative grid grid-cols-1">
            <div
              className="z-10 text-center text-lg font-bold row-start-1 col-start-1 mt-4"
              onClick={onTextClick}
            >
              {formik.values.amount !== "" && formatInput(formik.values.amount)}
            </div>
            <input
              name="amount"
              type="number"
              ref={inputRef}
              onChange={formik.handleChange}
              value={formik.values.amount}
              className="z-100 row-start-1 col-start-1 text-center border-0 text-lg font-bold rounded-lg p-4 w-full text-transparent"
            />
            <span className="font-bold absolute top-4 right-4">{currency}</span>
          </div>
          <div className="flex flex-row w-full items-center mt-6 lg:mt-0">
            <p>{shortFormFormatter.format(minLoanAmount)}</p>
            <input
              id="amount"
              name="amount"
              type="range"
              step={1000}
              min={minLoanAmount}
              max={maxLoanAmount}
              value={formik.values.amount}
              error={formik.errors.amount}
              onChange={formik.handleChange}
              className="flex-1 mx-4 appearance-none bg-float-purple-4 rounded h-2 accent-float-purple-3"
            />
            <p>{shortFormFormatter.format(maxLoanAmount)}</p>
          </div>
          {formik.errors?.amount && (
            <p className="text-functional-red-100">{formik.errors.amount}</p>
          )}
          <div>
            <p className="mb-3 mt-8 lg:mt-6">
              <b>Term</b> in months
            </p>
            <div className="flex flex-col w-full relative">
              <input
                name="term"
                value={formik.values.term}
                error={formik.errors.term}
                onChange={handleTerm}
                type="range"
                min={termsOfferArray[0][0]}
                max={termsOfferArray[termsOfferArray.length - 1][0]}
                step="3"
                list="datalist"
                className="items-center appearance-none rounded h-2 accent-float-purple-3 px-2 z-10 bg-transparent"
              />
              <div className="absolute flex px-2 justify-between w-full rounded bg-white">
                {termsOfferArray.map((term) => (
                  <span
                    className="bg-float-purple-3 rounded-full w-2 h-2"
                    key={term[0]}
                  ></span>
                ))}
              </div>
              <datalist id="datalist" className="flex justify-between mx-2">
                {termsOfferArray.map((term) => (
                  <option value={term[0]} className="text-sm mt-1">
                    {term[0]}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
        </div>
        <div className="pl-6 lg:pl-0 w-1/2 lg:w-full border-l-2 border-float-purple-4 lg:border-transparent">
          <div>
            <label htmlFor="grace_period" className="mb-3 flex">
              <b>Grace period</b>&nbsp; (no repayments)
              <Info
                classname="text-sm font-medium text-gray-700"
                value={"Defer the beginning of repayments"}
              />
            </label>
            <div className="flex gap-4 items-center">
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 0) && "text-gray-500 italic"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 0)}
                  id="grace_period"
                  className="mr-1 radiobutton disabled:bg-gray-500"
                  type="radio"
                  name="grace_period"
                  value={"0"}
                  checked={formik.values.grace_period === "0"}
                  onChange={formik.handleChange}
                />
                No, thanks
              </label>
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 3) &&
                  "text-gray-500 italic line-through"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 3)}
                  className="mr-1 radiobutton"
                  type="radio"
                  name="grace_period"
                  value={"3"}
                  checked={formik.values.grace_period === "3"}
                  onChange={formik.handleChange}
                />
                3 months
              </label>
              <label
                className={`text-sm flex items-center ${
                  disabledGrace(formik.values.term, 6) && "text-float-grey-50"
                }`}
              >
                <input
                  disabled={disabledGrace(formik.values.term, 6)}
                  className="mr-1 radiobutton"
                  type="radio"
                  name="grace_period"
                  value={"6"}
                  checked={formik.values.grace_period === "6"}
                  onChange={formik.handleChange}
                />
                6 months
              </label>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="activation_date"
              className="font-bold flex mt-4 lg:mt-6 mb-3"
            >
              Pay-out date
              <Info
                classname="text-sm font-medium text-gray-700"
                value={`Pay out date can be earliest ${payOutConfig?.lead_time} workdays from now`}
              />
            </label>
            <DatePicker
              selected={activationDate}
              onChange={handleDate}
              filterDate={isWeekday}
              minDate={payOutDate}
              maxDate={validMaxDate}
              name="activation_date"
              id="activation_date"
              className="rounded-lg border-transparent w-full"
              dateFormat="yyyy-MM-dd"
              popperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    enabled: true,
                    phase: "write",
                    fn: ({ state }) => {
                      state.styles.popper.zIndex = 11;
                    },
                  },
                ],
              }}
            />
            {accountVerifiedStatus !== "Verified" && (
              <div className="flex flex-row mt-4">
                <label htmlFor="payout-account-status" className="font-bold">
                  Payout account status
                </label>
                <p
                  id="payout-account-status"
                  className={`${getAccountStatusCSS(
                    accountVerifiedStatus
                  )} ml-2`}
                >
                  {accountVerifiedStatus}
                </p>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center mt-4 lg:mt-6">
            {accounts.length === 0 || accountVerifiedStatus !== "Verified" ? (
              <button
                type="submit"
                className="font-semibold w-full mb-4"
                onClick={() => showAccountVerificationModal()}
              >
                Verify bank account
              </button>
            ) : null}
            {!hasActiveConnections && (
              <button
                type="submit"
                className="font-semibold w-full mb-4"
                onClick={() => history.push("/connect")}
              >
                Connect bank account
              </button>
            )}
            <button
              type="submit"
              className="font-semibold w-full"
              disabled={
                !formik.isValid ||
                formik.values.amount === used_credit ||
                props.expiredOffer ||
                accounts.length === 0 ||
                accountVerifiedStatus !== "Verified" ||
                !hasActiveConnections
              }
              onClick={() => showAccountModal()}
            >
              Continue
            </button>
            <p className="text-xs my-2 w-3/4 text-center">
              {getContinueHelpText()}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
